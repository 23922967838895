import router from '../../../../plugins/router'
import { mapActions, mapState } from 'vuex'

import SLink from '../../../../components/ui/s-link'
import backIcon from '../../../../assets/images/issues/back_eco_icon.svg'

export default {
  name: 'IssueStatus',

  components: {
    SLink,
    IssueSuccess: () => import('../../../../components/IssueSuccess'),
  },

  async created() {
    if (this.issueId) {
      await this.getIssue(this.issueId)
      return
    }

    router.push({
      name: 'issues',
      params: { slug: this.slug, slugId: this.slugId },
    })
  },

  data() {
    const { slug, slugId, code, issueId } = router.currentRoute.params

    return {
      backIcon,
      code,
      completed: null,
      issueId,
      notice: '',
      showNotice: false,
      slug,
      slugId,
      status: 'NEW',
    }
  },

  computed: {
    ...mapState(['error', 'companyId', 'issue']),

    wasOpen() {
      return this.status == 'OPEN'
    },

    wasSolved() {
      return this.status == 'ANSWERED'
    },
  },

  methods: {
    ...mapActions(['getIssue']),

    openChat() {
      router.push({
        name: 'issue-chat',
        params: {
          slug: this.slug,
          code: this.code,
          issueId: this.issueId,
        },
      })
    },
  },

  watch: {
    error() {
      if (!this.issueId) {
        router.push({ name: 'issues', params: { slug: this.slug } })
      }
    },

    issue() {
      this.status = this.issue.status
      this.completed = this.issue.completed || null
    },
  },
}
